import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { Button } from 'components/Button';
import { Spinner } from 'components/Spinner';
import { useAuth } from 'hooks/useAuth';
import { ReactComponent as Info } from 'assets/icons/info.svg';

import { PhoneInput } from './components/PhoneInput';
import { PasswordInput } from './components/PasswordInput';

function LoginPage() {
  const { isLoading, isSignedIn, signIn } = useAuth();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: '/tendero-login',
        pageTitle: 'Tendero: Login',
      },
    });
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      navigate('/');
    }
  }, [isSignedIn, navigate]);

  const isValid = useMemo(
    () => (phoneNumber.match(/ /g) || []).length === 3 && password.length > 6,
    [password.length, phoneNumber],
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_flow',
        category: 'clic',
        action: 'clic_boton',
        label: 'Iniciar sesión',
      },
    });
    await signIn({
      phone_number: phoneNumber.replace(/\+52\s|\s+/g, ''),
      password: password,
    });
  };

  return (
    <>
      <Helmet>
        <title>Iniciar sesión | Descuentón</title>
      </Helmet>
      <main className="bg-hero-pattern min-h-full">
        <section className="w-full px-4 pb-4 pt-16 sm:mx-auto sm:max-w-sm sm:px-0">
          <img
            className="mx-auto h-[10rem] w-[10.25rem]"
            src="/logo.webp"
            alt="Descuentón"
          />
          <div className="mt-6 text-center">
            <h1 className="font-serif text-3xl font-bold text-white">¡Hola!</h1>
            <p className="mt-2 text-base text-white">
              Ingresa con tus datos de GETM
            </p>
          </div>
          <form className="mt-6" onSubmit={handleSubmit}>
            <PhoneInput
              name="phone_number"
              label="Número de celular"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              required
              placeholder="+52 334567654"
            />
            <PasswordInput
              className="mt-3"
              name="password"
              label="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              placeholder="000000000"
            />
            <p className="mb-2 mt-6 text-center text-xs text-white">
              Al iniciar sesión aceptas los{' '}
              <Link className="underline" to="/terms/terms">
                términos, condiciones
              </Link>{' '}
              y{' '}
              <Link className="underline" to="/terms/privacy">
                políticas de privacidad
              </Link>{' '}
              de Descuentón
            </p>
            <div className="text-center">
              <Button
                className="mx-auto mt-3"
                disabled={!isValid || isLoading}
                type="submit"
                variant={isLoading ? 'loading' : 'tertiary'}
              >
                Iniciar sesión
                {isLoading && <Spinner />}
              </Button>
            </div>
          </form>
          <div className="mt-6 flex justify-center gap-1">
            <Info />
            <p className="text-center text-xs text-white">
              ¿Olvidaste tu contraseña de GEPP en tus manos?
              <Link to="/recover" className="underline">
                {' '}
                Recupérala aquí
              </Link>
            </p>
          </div>
        </section>
      </main>
    </>
  );
}

export default LoginPage;
