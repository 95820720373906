import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { ChevronLeft as ChevronLeftIcon } from 'lucide-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { Button } from 'components/Button';
import { useToast } from 'hooks/useToast';
import contentService from 'services/content';
import { ReactComponent as Descuentón } from 'assets/images/descuenton.svg';

import { TERMS_TYPES } from './constants';

function TermsAndPrivacyPage() {
  const navigate = useNavigate();
  const { type } = useParams();
  const [content, setContent] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: `/tendero-${type === TERMS_TYPES.TERMS ? 'términos-y-condiciones' : 'políticas-de-privacidad'}`,
        pageTitle: `Tendero: ${type === TERMS_TYPES.TERMS ? 'Términos y condiciones' : 'Políticas de privacidad'}`,
      },
    });
  }, [type]);

  useEffect(() => {
    const getContent = async (contentType: string | undefined) => {
      let response;

      if (contentType === TERMS_TYPES.TERMS) {
        response = await contentService.getTermsAndConditions();
      } else {
        response = await contentService.getPrivacyPolicies();
      }
      const { successful, data } = response;
      if (successful) {
        setContent(data.content);
      } else {
        TagManager.dataLayer({
          dataLayer: {
            event: 'user_flow',
            category: 'system_message',
            action: 'error',
            label: 'Hay un fallo temporal, intenta más tarde',
          },
        });
        toast({
          description: 'Hay un fallo temporal, intenta más tarde',
          duration: 3000,
          variant: 'destructive',
        });
      }
    };

    getContent(type);
  }, [toast, type]);

  const handleBack = () => navigate(-1);

  return (
    <div className="flex min-h-full flex-col bg-white">
      <header className="flex items-center justify-between bg-white px-4 py-1">
        <Link to="/">
          <Descuentón className="h-16 w-[3.75rem]" />
        </Link>
      </header>
      <main className="flex flex-1 flex-col">
        <section className="w-full sm:mx-auto sm:max-w-sm">
          <Button id="history_back" onClick={handleBack} variant="link">
            <ChevronLeftIcon />
            Regresar
          </Button>
          <div className="flex flex-col gap-4 px-4 py-8 text-[#0C0D0F]">
            <div className="font-libre-franklin text-3xl font-bold">
              {type === TERMS_TYPES.TERMS
                ? 'Términos y condiciones'
                : 'Políticas de privacidad'}
            </div>
            <div className="font-roboto text-base font-normal ">
              {parse(content)}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default TermsAndPrivacyPage;
