import { Button } from 'components/Button';

interface FilterTabsOption<T> {
  value: T;
  label: string;
}

interface FilterTabsProps<T> {
  options: FilterTabsOption<T>[];
  filter: T;
  setFilter: React.Dispatch<React.SetStateAction<T>>;
}
function FilterTabs<T>({ options, filter, setFilter }: FilterTabsProps<T>) {
  return (
    <div className="flex w-full gap-2.5 bg-neutral-medium px-4 py-2">
      {options &&
        options.map((option, index) => (
          <Button
            key={index}
            onClick={() => setFilter(option.value)}
            variant={
              filter === option.value ? 'containeTertiary' : 'outlineTertiary'
            }
            size="sm"
          >
            {option.label}
          </Button>
        ))}
    </div>
  );
}

export default FilterTabs;
