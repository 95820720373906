import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import Footer from 'components/Footer';
import Header from 'components/Header';
import { cn } from 'utils/cn';
import BottomNavigation from 'components/BottomNavigation';

const layoutVariants = cva('flex min-h-screen flex-col', {
  variants: {
    variant: {
      white: 'bg-illustration-white',
      light: 'bg-illustration-light-blue',
      dark: 'bg-illustration-dark-blue',
      neutral: 'bg-white',
    },
  },
  defaultVariants: {
    variant: 'white',
  },
});

export interface LayoutProps extends VariantProps<typeof layoutVariants> {
  title?: string;
}

function Layout({
  children,
  title,
  variant,
}: React.PropsWithChildren<LayoutProps>) {
  return (
    <div className={cn(layoutVariants({ variant }))} data-testid="layout">
      <div className="mb-16 flex flex-1 flex-col overflow-auto">
        <Header title={title} />
        <main className="flex flex-1 flex-col">{children}</main>
        <Footer />
      </div>
      <BottomNavigation />
    </div>
  );
}

export default Layout;
