import * as React from 'react';

interface ChipInfoProps {
  title: string;
  value: string;
}

function ChipInfo({ title, value }: ChipInfoProps) {
  return (
    <div className="flex justify-between rounded-3xl bg-chip-neutral px-4 py-2 text-neutral-text-strong">
      <div className="text-base font-semibold">{title}</div>
      <div className="text-sm font-normal">{value}</div>
    </div>
  );
}

export default React.memo(ChipInfo);
