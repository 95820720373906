import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import Loader from 'components/Loader';
import AuthProvider from 'context/AuthProvider';
import Toaster from 'context/Toaster';
import AppRoutes from 'routes/AppRoutes';
import useConfigStore from 'stores/config-store';

function App() {
  const isLoading = useConfigStore(useShallow((state) => state.isLoading));
  const fetchConfig = useConfigStore(
    useShallow((state) => state.fetchGeneralConfig),
  );

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return (
    <>
      <Toaster />
      {isLoading && <Loader />}
      <BrowserRouter>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
