import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { Button } from 'components/Button';
import { ReactComponent as CouponBroken } from 'assets/images/coupon_broken.svg';
import { ReactComponent as Hourglass } from 'assets/images/hourglass.svg';
import { ReactComponent as NotFound } from 'assets/images/not_found.svg';

import { CouponErrorDetail, CouponErrorCodes } from '../types';

export interface CouponErrorProps {
  code: CouponErrorCodes;
}

const COUPON_ERRORS: Record<CouponErrorCodes, CouponErrorDetail> = {
  coupon_not_found: {
    illustration: <NotFound />,
    title: '¡Vaya! Este cupón no aparece registrado',
    description: 'Inténtalo de nuevo',
    analitics: {
      path: '/tendero-cupon-no-encontrado',
      title: 'Tendero: Cupon no encontrado',
    },
  },
  coupon_redeemed: {
    illustration: <CouponBroken />,
    title: 'Cupón ya utilizado',
    description: 'Esta promoción ya fue canjeada',
    analitics: {
      path: '/tendero-cupon-ya-utilizado',
      title: 'Tendero: Cupon ya utilizado',
    },
  },
  expired_promotion: {
    illustration: <Hourglass />,
    title: '¡Oh, no!  La oferta expiró',
    description: 'Esta promoción ya no está disponible',
    analitics: {
      path: '/tendero-cupon-expirado',
      title: 'Tendero: Cupon expirado',
    },
  },
  redemption_already_exceeded: {
    illustration: <CouponBroken />,
    title: 'Cupón ya utilizado',
    description: 'Esta promoción ya fue canjeada',
    analitics: {
      path: '/tendero-cupon-ya-utilizado',
      title: 'Tendero: Cupon ya utilizado',
    },
  },
  redemption_limit_exceeded: {
    illustration: <CouponBroken />,
    title: 'Cupón ya utilizado',
    description: 'Esta promoción ya fue canjeada',
    analitics: {
      path: '/tendero-cupon-ya-utilizado',
      title: 'Tendero: Cupon ya utilizado',
    },
  },
  history_not_found: {
    illustration: <NotFound />,
    title: '¡Vaya! Este cupón no aparece registrado',
    description: 'Inténtalo de nuevo',
    analitics: {
      path: '/tendero-cupon-no-encontrado',
      title: 'Tendero: Cupon no encontrado',
    },
  },
};

function CouponError({ code }: CouponErrorProps) {
  const navigate = useNavigate();
  const error = COUPON_ERRORS[code];

  const handleBack = () => navigate(-1);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: error.analitics.path,
        pageTitle: error.analitics.title,
      },
    });
  }, [error.analitics.path, error.analitics.title]);

  return (
    <section className="flex flex-1 flex-col items-center justify-between px-4 py-12">
      <div className="flex flex-col items-center gap-6">
        {error.illustration}
        <div className="flex flex-col items-center gap-3 text-center">
          <h1 className="font-serif text-2xl font-bold text-black">
            {error.title}
          </h1>
          <p className="text-sm text-black">{error.description}</p>
        </div>
      </div>
      <Button onClick={handleBack} variant="link">
        Continuar
      </Button>
    </section>
  );
}

export default React.memo(CouponError);
