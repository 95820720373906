import { useCallback, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

import DateRangePicker from 'components/DateRangePicker';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { ToastAction } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import usePrevious from 'hooks/usePrevious';
import { useToast } from 'hooks/useToast';
import redemptionHistoryService, { Movement } from 'services/redemptionHistory';
import { getFirstAndLastDayOfMonth } from 'utils/date';

import MovementItem from './components/MovementItem';
import FilterTabs, { FilterOptions, MovementType } from 'components/FilterTabs';

const { firstDay, lastDay } = getFirstAndLastDayOfMonth();

const filterOptions: FilterOptions<MovementType>[] = [
  {
    label: 'Todos',
    value: 'all',
  },
  {
    label: 'Canjes',
    value: 'exchange',
  },
  {
    label: 'Bonificaciones',
    value: 'bonification',
  },
];

function MovementsScreen() {
  const { session, shopkeeper } = useAuth();
  const { toast } = useToast();
  const [date, setDate] = useState<DateRange | undefined>({
    from: firstDay,
    to: lastDay,
  });
  const prevDate = usePrevious<DateRange | undefined>(date);
  const [filter, setFilter] = useState<MovementType>('all');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [movements, setMovements] = useState<Movement[]>([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await redemptionHistoryService.getMovementHistory({
        shopkeeper_id: session?.shopkeeper_id ?? 1,
      });
      setMovements(response.successful ? response.data : []);
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: {
          shopkeeper_id: session?.shopkeeper_id ?? 0,
          flow: 'get_movements',
        },
      });
      if (error.code !== 'history_not_found') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'user_flow',
            category: 'system_message',
            action: 'error',
            label: error.message,
          },
        });
        toast({
          action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
          description: error.message,
          duration: 3000,
          variant: 'destructive',
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [session?.shopkeeper_id, toast]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: '/tendero-historial-de-movimientos',
        pageTitle: 'Tendero: Historial de movimientos',
      },
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchRedemptionHistory = async () => {
      try {
        if (date?.from) {
          const response = await redemptionHistoryService.getMovementHistory({
            shopkeeper_id: session?.shopkeeper_id ?? 0,
            date_from: date?.from.toISOString().substring(0, 10),
            date_to:
              date?.to?.toISOString().substring(0, 10) ||
              date?.from.toISOString().substring(0, 10),
          });
          setMovements(response.successful ? response.data : []);
        } else {
          const response = await redemptionHistoryService.getMovementHistory({
            shopkeeper_id: session?.shopkeeper_id ?? 0,
          });
          setMovements(response.successful ? response.data : []);
        }
      } catch (error: any) {
        setMovements([]);
        Sentry.captureException(error, {
          tags: {
            shopkeeper_id: session?.shopkeeper_id ?? 0,
            flow: 'get_movements_by_date',
          },
        });
        if (error.code !== 'history_not_found') {
          TagManager.dataLayer({
            dataLayer: {
              event: 'user_flow',
              category: 'system_message',
              action: 'error',
              label: error.message,
            },
          });
          toast({
            action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
            description: error.message,
            duration: 3000,
            variant: 'destructive',
          });
        }
      }
      setIsLoading(false);
    };

    if (prevDate !== date && !isCalendarOpen) {
      fetchRedemptionHistory();
    }
  }, [date, isCalendarOpen, prevDate, session?.shopkeeper_id, toast]);

  const filteredMovements = movements
    ? movements.filter((movement) => {
        if (filter === 'all') return true;
        return movement.type === filter;
      })
    : [];

  return (
    <>
      <Helmet>
        <title>Tu historial de movimientos | Descuentón</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Layout title="Movimientos" variant="neutral">
          <section className="w-full sm:mx-auto sm:max-w-sm">
            <div className="flex flex-col gap-4 pb-8">
              <div className="px-4">
                <div className="py-1">
                  <p className="text-sm font-bold text-black">
                    Bodega: {shopkeeper?.warehouse_number}
                  </p>
                  <p className="text-sm font-bold text-black">
                    NUD: {shopkeeper?.NUD}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-4 px-4">
                <DateRangePicker
                  date={date}
                  onChange={setDate}
                  onChangeOpenStatus={setIsCalendarOpen}
                />
              </div>
              <div>
                <FilterTabs
                  options={filterOptions}
                  filter={filter}
                  setFilter={setFilter}
                />
                {filteredMovements.length > 0 ? (
                  <div className="divide-neutral-weak border-neutral-weak divide-y border-b px-4">
                    {filteredMovements.map((movement) => (
                      <MovementItem {...movement} />
                    ))}
                  </div>
                ) : (
                  <div className="mt-8 flex flex-col items-center gap-4 text-center">
                    <img
                      className="h-[4.28125rem] w-[6.25rem]"
                      src="/sin-movimientos.webp"
                      alt="historial"
                    />
                    <p className="font-serif text-xl font-bold text-black">
                      Todavía no tienes ningún
                      <br />
                      movimiento registrado
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
        </Layout>
      )}
    </>
  );
}

export default MovementsScreen;
