import { Link, useNavigate } from 'react-router-dom';

interface HeaderProps {
  title?: string;
}

function Header({ title }: HeaderProps) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <header className="w-full">
      {title ? (
        <div className="mx-4 flex max-w-sm flex-row gap-6 py-6 pb-4 sm:mx-auto">
          <button onClick={goBack}>
            <svg
              className="h-6 w-6 text-black"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              viewBox="0 -960 960 960"
              fill="currentColor"
            >
              <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
            </svg>
          </button>
          <p className="font-serif text-xl font-bold text-black">{title}</p>
        </div>
      ) : (
        <div className="mx-auto flex max-w-sm flex-row items-center justify-center py-1">
          <Link to="/">
            <img
              className="h-16 w-[4.07875]"
              src="/logo-small.webp"
              alt="Descuentón"
            />
          </Link>
        </div>
      )}
    </header>
  );
}

export default Header;
