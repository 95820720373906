import React, { useMemo } from 'react';
import InputMask from 'react-input-mask';
import { useShallow } from 'zustand/react/shallow';

import useConfigStore from 'stores/config-store';

export interface PhoneInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  helperText?: string;
  label: string;
  required: boolean;
}

const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    { className, helperText, label, placeholder = ' ', required, ...props },
    ref,
  ) => {
    const config = useConfigStore(useShallow((state) => state.data));

    const countryCode = useMemo(() => {
      const countryCodes = config.find(
        (element) => element.type === 'phone_code',
      );

      if (countryCodes && countryCodes.values.length > 0) {
        return `+${countryCodes.values[0].value}`;
      }

      return '+52';
    }, [config]);

    return (
      <div className={className}>
        <div className="flex flex-col gap-1">
          <label className="pl-3 text-sm text-white">
            {label}{' '}
            {required && <span className="text-xs text-secondary-text">*</span>}
          </label>
          <InputMask
            {...props}
            mask={`${countryCode} 999 999 9999`}
            maskChar=" "
            type="tel"
            className="peer block w-full appearance-none rounded-lg border border-tertiary-weak bg-white/10 px-2.5 py-4 text-sm text-white focus:border-2 focus:outline-none focus:ring-0"
            placeholder={placeholder}
          />
        </div>
        {helperText && <p className="mt-2 text-xs text-white">{helperText}</p>}
      </div>
    );
  },
);

export { PhoneInput };
