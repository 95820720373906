import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import Layout from 'components/Layout';

function NotFoundPage() {
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  return (
    <>
      <Helmet>
        <title>Página no encontrada | Descuentón</title>
      </Helmet>
      <Layout>
        <section className="align-center flex flex-1 flex-col justify-center gap-6 px-4 text-center">
          <div>
            <h1 className="font-serif text-3xl font-bold text-black">
              Página no encontrada
            </h1>
            <p className="mt-2 text-sm text-black">
              Lo sentimos, no pudimos encontrar la página que estás buscando.
            </p>
          </div>
          <div>
            <Button id="not_found_back" onClick={handleBack}>
              Regresar
            </Button>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default NotFoundPage;
