import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute';
import HomePage from 'pages/Home';
import LoginPage from 'pages/Login';
import NotFoundPage from 'pages/NotFound';
import CouponPage from 'pages/Coupon';
import ProfilePage from 'pages/Profile';
import HistoryPage from 'pages/History';
import TermsAndPrivacyPage from 'pages/TermsAndPrivacy';
import MovementsScreen from 'pages/Movements';
import RecoverPage from 'pages/Recover';

function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/coupon/:coupon_code"
        element={
          <ProtectedRoute>
            <CouponPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/redemptions"
        element={
          <ProtectedRoute>
            <HistoryPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="movements"
        element={
          <ProtectedRoute>
            <MovementsScreen />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/terms/:type" element={<TermsAndPrivacyPage />} />
      <Route path="/recover" element={<RecoverPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRoutes;
