import React from 'react';

import { ReactComponent as CircularProgress } from 'assets/images/circular-progress.svg';

function Loader() {
  return (
    <div className="absolute inset-0 z-20 flex flex-col justify-between bg-white px-4 py-28">
      <div className="flex flex-col gap-10">
        <p className="font-serif text-3xl font-bold text-blue-500">¡Hola!</p>
        <p className="font-serif text-3xl font-bold text-blue-500">
          Estamos alistando tu
          <br />
          portal
        </p>
      </div>
      <div className="flex flex-col items-center justify-center gap-2">
        <CircularProgress className="animate-spin" />
        <p className="text-sm text-black">Cargando</p>
      </div>
      <div className="h-[9.25rem]" />
    </div>
  );
}

export default React.memo(Loader);
