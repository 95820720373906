import React from 'react';
import InputMask from 'react-input-mask';

export interface CouponInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const CouponInput = React.forwardRef<HTMLInputElement, CouponInputProps>(
  ({ label, name, placeholder = ' ', ...props }, ref) => {
    return (
      <div className="relative z-0 mt-1">
        <InputMask
          {...props}
          mask="***-***-**"
          type="text"
          name={name}
          alwaysShowMask={true}
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-center text-sm text-gray-900 focus:border-tertiary focus:outline-none focus:ring-0"
          placeholder={placeholder}
        />
        <label
          htmlFor={name}
          className="absolute left-1/4 top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm font-bold text-neutral-disabled duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4"
        >
          {label}
        </label>
      </div>
    );
  },
);

export default CouponInput;
