import { QrScanner } from '@yudiel/react-qr-scanner';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { ToastAction } from 'components/Toast';
import { toast } from 'hooks/useToast';
import { ReactComponent as TimesIcon } from 'assets/icons/times.svg';

function CouponScaner() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isScanVisible, setIsScanVisible] = useState<boolean>(false);
  const [stopDecoding, setStopDecoding] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams.get('scanning')) {
      setIsScanVisible(true);
    }
  }, [searchParams]);

  const openScan = () => {
    setStopDecoding(false);
    setIsScanVisible(true);
  };

  const closeScan = () => {
    setStopDecoding(true);
    setIsScanVisible(false);
  };

  const handleScan = (text: string) => {
    setIsScanVisible(false);
    setStopDecoding(true);
    navigate(`/coupon/${text}`);
  };

  const handleError = (error: Error) => {
    if (error.name === 'NotAllowedError') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_flow',
          category: 'system_message',
          action: 'error',
          label: error.message,
        },
      });
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description:
          '¡Falta autorización! Dale permiso a tu cámara para escanear',
        duration: 3000,
        variant: 'destructive',
      });
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_flow',
          category: 'system_message',
          action: 'error',
          label: error.message,
        },
      });
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description: error.message,
        duration: 3000,
        variant: 'destructive',
      });
    }
  };

  useEffect(() => {
    if (isScanVisible) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'page_view',
          pagePath: '/tendero-qr',
          pageTitle: 'Tendero: QR',
        },
      });
    }
  }, [isScanVisible]);

  if (isScanVisible) {
    return (
      <div className="relative">
        <TimesIcon
          className="absolute right-3 top-3 z-50"
          onClick={closeScan}
        />

        <QrScanner
          stopDecoding={stopDecoding}
          onDecode={handleScan}
          onError={handleError}
          audio={false}
        />
      </div>
    );
  }

  return (
    <button
      className="flex h-[6.25rem] items-center gap-4 rounded-full bg-gradient-to-r from-yellow-500 to-yellow-700 px-10"
      onClick={openScan}
    >
      <svg
        className="h-12 w-12 text-primary"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z" />
      </svg>
      <p className="text-base font-semibold text-primary">
        Escanea el código QR
      </p>
    </button>
  );
}

export default CouponScaner;
