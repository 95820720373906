import React, { useState } from 'react';

export interface PasswordInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  required: boolean;
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, label, placeholder = ' ', required, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

    return (
      <div className={className}>
        <div className="relative flex flex-col gap-1">
          <label className="pl-3 text-sm text-white">
            {label}{' '}
            {required && <span className="text-xs text-secondary-text">*</span>}
          </label>
          <input
            {...props}
            ref={ref}
            type={showPassword ? 'text' : 'password'}
            className="peer block w-full appearance-none rounded-lg border border-tertiary-weak bg-white/10 px-2.5 py-4 text-sm text-white focus:border-2 focus:outline-none focus:ring-0"
            placeholder={placeholder}
          />
          <button
            onClick={togglePasswordVisibility}
            className="absolute bottom-0 right-0 top-6 flex items-center pr-3 text-sm leading-5"
            type="button"
          >
            {showPassword ? (
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#ffffff"
              >
                <path d="M792-56 624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM480-320q11 0 20.5-1t20.5-4L305-541q-3 11-4 20.5t-1 20.5q0 75 52.5 127.5T480-320Zm292 18L645-428q7-17 11-34.5t4-37.5q0-75-52.5-127.5T480-680q-20 0-37.5 4T408-664L306-766q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302ZM587-486 467-606q28-5 51.5 4.5T559-574q17 18 24.5 41.5T587-486Z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#ffffff"
              >
                <path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Z" />
              </svg>
            )}
          </button>
        </div>
      </div>
    );
  },
);

export { PasswordInput };
