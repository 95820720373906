import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { Button } from 'components/Button';

import CouponInput from './CouponInput';
import CouponScaner from './CouponScaner';

function CouponFinder() {
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState<string>('');

  const isValid = useMemo(
    () => coupon.length === 10 && !coupon.match('_')?.length,
    [coupon],
  );

  const handleSubmit = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_flow',
        category: 'clic',
        action: 'clic_boton',
        label: 'Encontrar cupón',
      },
    });
    navigate(`/coupon/${coupon}`);
  };

  return (
    <div>
      <p className="mb-2 text-sm font-bold text-white">
        Valida el cupón de Descuentón acá:
      </p>
      <div className="flex flex-col gap-4 rounded-xl bg-white px-4 py-6">
        <CouponScaner />
        <CouponInput
          name="coupon"
          label="Ingresar el número manualmente"
          onChange={(e) => setCoupon(e.target.value)}
          value={coupon}
        />
        <div className="text-center">
          <Button disabled={!isValid} onClick={handleSubmit} variant="tertiary">
            Validar cupón
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CouponFinder;
