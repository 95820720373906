import { Movement } from 'services/redemptionHistory';

export interface MovementItemProps extends Movement {}

function MovementItem({
  bonification,
  bonification_format_date,
  bonification_time,
  exchange_time,
  promotion_name,
  redemption_format_date,
  revenue,
}: MovementItemProps) {
  return (
    <div className="flex items-center gap-2 py-2">
      <div className="flex flex-1 flex-col">
        <p className="text-base text-black">{promotion_name}</p>
        <p className="text-xs text-neutral-text">
          {redemption_format_date ?? bonification_format_date}{' '}
          {exchange_time ?? bonification_time}
        </p>
      </div>
      <p
        className={`text-base font-semibold text-black ${revenue && 'text-success'} ${bonification && 'text-error'}`}
      >
        {`${bonification ? '-' : ''}$${revenue ?? bonification}`}
      </p>
    </div>
  );
}

export default MovementItem;
