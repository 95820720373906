import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

function ProtectedRoute({ children }: PropsWithChildren) {
  const { isSignedIn } = useAuth();

  if (!isSignedIn) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;
