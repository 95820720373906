import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="flex flex-col items-center gap-2.5 bg-blue-700 py-6">
      <Link className="text-sm text-white underline" to="/terms/terms">
        Términos y condiciones
      </Link>
      <Link className="text-sm text-white underline" to="/terms/privacy">
        Políticas de privacidad
      </Link>
      <p className="text-sm text-white">© 2024 Descuentón</p>
    </footer>
  );
}

export default React.memo(Footer);
