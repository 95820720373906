import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';

import { Button } from 'components/Button';
import Layout from 'components/Layout';
import Modal from 'components/Modal';
import { useAuth } from 'hooks/useAuth';

function ProfilePage() {
  const { shopkeeper, signOut } = useAuth();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: '/tendero-perfil',
        pageTitle: 'Tendero: Perfil',
      },
    });
  }, []);

  const handleOpenModal = () => {
    setOpen(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_flow',
        category: 'clic',
        action: 'clic_boton',
        label: 'Cerrar sesión',
      },
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_flow',
        category: 'clic',
        action: 'clic_boton',
        label: 'No, regresar',
      },
    });
  };

  const handleSignOut = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_flow',
        category: 'clic',
        action: 'clic_boton',
        label: 'Sí, cerrar sesión',
      },
    });
    signOut();
  };

  return (
    <>
      <Helmet>
        <title>Mi Perfil | Descuentón</title>
      </Helmet>
      <Layout title="Perfil" variant="white">
        <section className="flex w-full flex-col gap-6 px-4 py-8 sm:mx-auto sm:max-w-sm sm:px-0">
          <h1 className="font-serif text-3xl font-bold text-black">Perfil</h1>
          <ul>
            <li className="py-2">
              <p className="font-serif text-xs font-semibold text-neutral-disabled">
                Número celular
              </p>
              <p className="text-base text-black">{shopkeeper?.phone_number}</p>
            </li>
            <hr />
            <li className="py-2">
              <p className="font-serif text-xs font-semibold text-neutral-disabled">
                NUD
              </p>
              <p className="text-base text-black">{shopkeeper?.NUD}</p>
            </li>
            <hr />
            <li className="py-2">
              <p className="font-serif text-xs font-semibold text-neutral-disabled">
                Bodega
              </p>
              <p className="text-base text-black">
                {shopkeeper?.warehouse_number}
              </p>
            </li>
            <hr />
          </ul>
          <div className="text-center">
            <Button onClick={handleOpenModal} variant="link">
              Cerrar sesión
            </Button>
          </div>
        </section>
      </Layout>
      <Modal open={open} onClose={setOpen}>
        <div className="flex flex-col items-center gap-6">
          <h3 className="font-serif text-xl font-bold text-black">
            ¿Deseas cerrar sesión?
          </h3>
          <div className="flex flex-col items-center gap-2">
            <Button
              id="sign_out_confirmation_button"
              onClick={handleSignOut}
              variant="tertiary"
            >
              Sí, cerrar sesión
            </Button>
            <Button
              id="close_modal_button"
              onClick={handleCloseModal}
              variant="link"
            >
              No, regresar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProfilePage;
