import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { Button } from 'components/Button';
import Lottie from 'lottie-react';
import PepsiAnimationJSON from 'assets/animations/Pepsi+confeti.json';
import ChipInfo from './ChipInfo';
import { useAuth } from 'hooks/useAuth';

export interface RedemptionSuccessProps {
  amount?: number;
  available_money?: number;
}

function RedemptionSuccess({
  amount = 1,
  available_money = 0,
}: RedemptionSuccessProps) {
  const navigate = useNavigate();
  const { shopkeeper } = useAuth();

  const goToHome = () => navigate(-1);

  const goToMovements = () => navigate('/movements', { replace: true });

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: '/tendero-canje-exitoso',
        pageTitle: 'Tendero: Canje exitoso',
      },
    });
  }, []);

  return (
    <section className="flex flex-1 flex-col justify-between gap-4 px-4 py-8">
      <div className="flex flex-col items-center gap-4">
        <Lottie animationData={PepsiAnimationJSON} />
        <div className="flex flex-col gap-3 text-center">
          <h1 className="font-serif text-2xl font-bold text-black">
            ¡Canje exitoso!
          </h1>
        </div>
      </div>
      <div className="py-3 text-sm font-extrabold text-neutral-text-strong">
        Bodega: {shopkeeper?.warehouse_number}
        <br />
        NUD: {shopkeeper?.NUD}
      </div>
      <ChipInfo title="Dinero ganado con este canje" value={`+ $${amount}`} />
      <ChipInfo title="Saldo disponible" value={`$${available_money}`} />
      <div className="flex flex-col items-center gap-2">
        <Button onClick={goToMovements} variant="outline">
          Ir a movimientos
        </Button>
        <Button onClick={goToHome}>Aceptar</Button>
      </div>
    </section>
  );
}

export default React.memo(RedemptionSuccess);
