import * as Sentry from '@sentry/react';

import configService from 'services/config';
import type { Config } from 'services/config';

import { create } from './utils';

interface ConfigState {
  isLoading: boolean;
  data: Config[];
}

interface Actions {
  fetchGeneralConfig: () => Promise<void>;
}

const useConfigStore = create<ConfigState & Actions>()((set) => ({
  isLoading: false,
  data: [],
  fetchGeneralConfig: async () => {
    try {
      set(() => ({ isLoading: true }));
      const response = await configService.getGeneralConfig();
      set(() => ({ data: response.data }));
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          flow: 'get_general_config',
        },
      });
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));

export default useConfigStore;
