import { persist } from 'zustand/middleware';
import * as Sentry from '@sentry/react';

import redemptionHistoryService, {
  Redemptions,
} from 'services/redemptionHistory';

import { create } from './utils';

interface RedemptionHistoryState {
  isLoading: boolean;
  redemptions: Redemptions;
}

interface Actions {
  fetchRedemptionHistory: (shopkeeperId: number) => Promise<void>;
}

const useRedemptionHistoryStore = create<RedemptionHistoryState & Actions>()(
  persist(
    (set) => ({
      isLoading: false,
      redemptions: {
        count: 0,
        total_revenue: 0,
        last_movement_date: '',
        last_movement_time: '',
        lastMovement_format_date: '',
      },
      fetchRedemptionHistory: async (shopkeeperId: number) => {
        try {
          set(() => ({ isLoading: true }));
          const response = await redemptionHistoryService.getTotalRedemptions({
            shopkeeper_id: shopkeeperId,
          });
          set(() => ({ redemptions: response.data }));
        } catch (error) {
          Sentry.captureException(error, {
            tags: {
              shopkeeper_id: shopkeeperId,
              flow: 'get_movements_by_date',
            },
          });
          throw error;
        } finally {
          set(() => ({ isLoading: false }));
        }
      },
    }),
    {
      name: 'redemption-history-storage',
    },
  ),
);

export default useRedemptionHistoryStore;
