import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { firstDateInProduction } from './constants';

export const getFirstAndLastDayOfMonth = (): {
  firstDay: Date;
  lastDay: Date;
} => {
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  return { firstDay, lastDay };
};

export function formatDateRange(dateFrom: Date, dateTo?: Date): string {
  if (!dateTo) {
    return format(dateFrom, "d 'de' MMMM 'del' yyyy", { locale: es });
  }

  const formattedDateFrom = format(dateFrom, 'MMM d, yyyy', { locale: es });
  const formattedDateTo = format(dateTo, 'MMM d, yyyy', { locale: es });

  return `${formattedDateFrom} - ${formattedDateTo}`;
}

export function getDateThreeMonthsAgo(): Date {
  const today = new Date();
  const threeMonthsAgo = new Date(today);

  threeMonthsAgo.setMonth(today.getMonth() - 2);

  threeMonthsAgo.setDate(1);

  return firstDateInProduction >= threeMonthsAgo
    ? firstDateInProduction
    : threeMonthsAgo;
}

export const formatISOLocalDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};
