import authClient from 'services/clients/authClient';

import {
  GetMovementHistoryRequest,
  GetMovementHistoryResponse,
  GetRedemptionHistoryRequest,
  GetRedemptionHistoryResponse,
  GetTotalRedemptionsRequest,
  GetTotalRedemptionsResponse,
} from './types';

export const getTotalRedemptions = async ({
  shopkeeper_id,
  date_from,
  date_to,
}: GetTotalRedemptionsRequest): Promise<GetTotalRedemptionsResponse> => {
  return await authClient.get(
    `v1/redemption-history/total/${shopkeeper_id}${date_from && date_to ? `?date_from=${date_from}&date_to=${date_to}` : ''}`,
  );
};

export const getRedemptionHistory = async ({
  date_from,
  date_to,
  shopkeeper_id,
}: GetRedemptionHistoryRequest): Promise<GetRedemptionHistoryResponse> => {
  return await authClient.get(
    `v1/redemption-history/${shopkeeper_id}${date_from && date_to ? `?date_from=${date_from}&date_to=${date_to}` : ''}`,
  );
};

export const getMovementHistory = async ({
  date_from,
  date_to,
  shopkeeper_id,
}: GetMovementHistoryRequest): Promise<GetMovementHistoryResponse> => {
  return await authClient.get(
    `v1/redemption-history/detail/${shopkeeper_id}${date_from && date_to ? `?date_from=${date_from}&date_to=${date_to}` : ''}`,
  );
};
