import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ReactComponent as Arrow } from 'assets/icons/arrow_back_ios.svg';
import { Button } from 'components/Button';
import { useNavigate } from 'react-router-dom';

const openGETM = () => {
  window.open(process.env.REACT_APP_GETM_URL, '_blank', 'noopener');
};

function RecoverPage() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Recuperar contraseña | Descuentón</title>
      </Helmet>
      <main className="bg-hero-pattern min-h-full">
        <section className="flex w-full flex-col gap-4 px-4 pb-10 pt-6 sm:mx-auto sm:max-w-sm sm:px-0">
          <Arrow onClick={() => navigate(-1)} />
          <h2 className="text-center font-serif text-xl font-semibold text-white">
            Te explicamos <br /> cómo recuperar tu contraseña
          </h2>
          <div className="mx-4 flex flex-col items-center gap-5 rounded-lg bg-white px-7 pt-8 shadow-simple">
            <p className="text-center text-base text-blue-400">
              Abre la aplicación de GEPP en tus manos y da clic en
              <b> "Recuperar contraseña"</b> en la pantalla de inicio de sesión.
              <br />
              Recuerda <b>volver a la App de Descuentón</b> una vez la
              recuperes.
            </p>
            <img
              className="w-52"
              src="/recover-GETM.png"
              alt="Screenshot GETM"
            />
          </div>
          <Button className="mx-4" onClick={openGETM} variant="tertiary">
            Abrir GEPP en tus manos
          </Button>
        </section>
      </main>
    </>
  );
}

export default React.memo(RecoverPage);
