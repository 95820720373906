import CryptoJS from 'crypto-js';
import { useState } from 'react';

const useSecureStorage = <T>(
  key: string,
  initialValue: T,
): [T, (value: T | ((val: T) => T)) => void, () => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);

      if (item) {
        const bytes = CryptoJS.AES.decrypt(
          item,
          process.env.REACT_APP_ENCRYPTION_KEY!,
        );
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData ? (JSON.parse(decryptedData) as T) : initialValue;
      }

      return initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(valueToStore),
      process.env.REACT_APP_ENCRYPTION_KEY!,
    ).toString();
    localStorage.setItem(key, ciphertext);
  };

  const removeValue = (): void => {
    localStorage.removeItem(key);
    setStoredValue(undefined as unknown as T);
  };

  return [storedValue, setValue, removeValue];
};

export default useSecureStorage;
