import { Link, NavLink } from 'react-router-dom';

function BottomNavigation() {
  return (
    <div className="fixed bottom-0 left-0 z-50 h-16 w-full bg-white">
      <div className="mx-auto grid h-full max-w-md grid-cols-4 font-medium">
        <NavLink
          to="/profile"
          className="group inline-flex flex-col items-center justify-center px-4"
        >
          {({ isActive }) => (
            <>
              <svg
                className={`mb-2 h-6 w-6 ${isActive ? 'text-black' : 'text-neutral-disabled'}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 -960 960 960"
              >
                <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z" />
              </svg>
              <span
                className={`font-regular font-mono text-2xs ${isActive ? 'text-black' : 'text-neutral-disabled'}`}
              >
                Perfil
              </span>
            </>
          )}
        </NavLink>
        <NavLink
          to="/"
          className="group inline-flex flex-col items-center justify-center px-4"
        >
          {({ isActive }) => (
            <>
              <svg
                className={`mb-2 h-6 w-6 ${isActive ? 'text-black' : 'text-neutral-disabled'}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
              </svg>
              <span
                className={`font-regular font-mono text-2xs ${isActive ? 'text-black' : 'text-neutral-disabled'}`}
              >
                Inicio
              </span>
            </>
          )}
        </NavLink>
        <Link
          to="/?scanning=true"
          className="group inline-flex flex-col items-center justify-center px-4"
        >
          <svg
            className="mb-2 h-6 w-6 text-neutral-disabled"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 -960 960 960"
          >
            <path d="M120-520v-320h320v320H120Zm80-80h160v-160H200v160Zm-80 480v-320h320v320H120Zm80-80h160v-160H200v160Zm320-320v-320h320v320H520Zm80-80h160v-160H600v160Zm160 480v-80h80v80h-80ZM520-360v-80h80v80h-80Zm80 80v-80h80v80h-80Zm-80 80v-80h80v80h-80Zm80 80v-80h80v80h-80Zm80-80v-80h80v80h-80Zm0-160v-80h80v80h-80Zm80 80v-80h80v80h-80Z" />
          </svg>
          <span className="font-regular font-mono text-2xs text-neutral-disabled">
            Escanear QR
          </span>
        </Link>
        <NavLink
          to="/redemptions"
          className="group inline-flex flex-col items-center justify-center px-4"
        >
          {({ isActive }) => (
            <>
              <svg
                className={`mb-2 h-6 w-6 ${isActive ? 'text-black' : 'text-neutral-disabled'}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 -960 960 960"
              >
                <path d="m368-320 112-84 110 84-42-136 112-88H524l-44-136-44 136H300l110 88-42 136ZM160-160q-33 0-56.5-23.5T80-240v-135q0-11 7-19t18-10q24-8 39.5-29t15.5-47q0-26-15.5-47T105-556q-11-2-18-10t-7-19v-135q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v135q0 11-7 19t-18 10q-24 8-39.5 29T800-480q0 26 15.5 47t39.5 29q11 2 18 10t7 19v135q0 33-23.5 56.5T800-160H160Zm0-80h640v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102Zm320-240Z" />
              </svg>
              <span
                className={`font-regular font-mono text-2xs ${isActive ? 'text-black' : 'text-neutral-disabled'}`}
              >
                Cupones
              </span>
            </>
          )}
        </NavLink>
      </div>
    </div>
  );
}

export default BottomNavigation;
